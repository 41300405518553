import { template as template_5fd91577c0bf4416b428631608708b19 } from "@ember/template-compiler";
const FKLabel = template_5fd91577c0bf4416b428631608708b19(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
