import { template as template_15b9e31e12134c0f847a9780cfe5a019 } from "@ember/template-compiler";
const FKText = template_15b9e31e12134c0f847a9780cfe5a019(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
