import { template as template_a6b414fbf4c6419da6af77e5d79bffe4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a6b414fbf4c6419da6af77e5d79bffe4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
