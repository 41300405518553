import { template as template_ec9b0c7857db421081312454da25f3a1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ec9b0c7857db421081312454da25f3a1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
